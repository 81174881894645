import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import useTokenStore from '@/store/token/useTokenStore';
import useProfileStore from '@/store/profile/useProfileStore';
import { isEmpty } from '@/shared/helpers/formatStrings/formatStrings';
import i18n from '@/plugins/i18n/i18n';
import {
  RouteAuthenticationRequirement,
  Locale,
  Feature,
  Routes,
} from '@/shared/types/generic';
import useTranslationsStore from '@/store/translations/useTranslationsStore';
import useSurveysStore from '@/store/surveys/useSurveysStore';
import { CollectableTypeName, UserProfile } from '@/shared/types/profile';
import useKudosStore from '@/store/kudos/useKudosStore';
import {
  AppHeaderType,
  AppLayoutType,
  AppTab,
} from '@/shared/types/components';
import useBiometric from '@/composables/useBiometric/useBiometric';
import useNativePlatform from '@/composables/useNativePlatform/useNativePlatform';
import useNotificationCenterStore from '@/store/notificationCenter/useNotificationCenterStore';
import { useFeatures } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import featureTogglesService from '@/shared/services/featureTogglesService/featureTogglesService';

const { isFeatureEnabled, loadFeatures, resetFeatures } = useFeatures();

const LoginView = () =>
  import(
    /* webpackChunkName: "authentication" */ '@/views/LoginView/LoginView.vue'
  );

const ActivateBiometricView = () =>
  import(
    /* webpackChunkName: "authentication" */ '@/views/ActivateBiometricView/ActivateBiometricView.vue'
  );

const ChangePasswordView = () =>
  import(
    /* webpackChunkName: "authentication" */ '@/views/ChangePasswordView/ChangePasswordView.vue'
  );

const VerifyPasswordView = () =>
  import(
    /* webpackChunkName: "authentication" */ '@/views/VerifyPasswordView/VerifyPasswordView.vue'
  );

const VerifyEmailCodeView = () =>
  import(
    /* webpackChunkName: "authentication" */ '@/views/VerifyEmailCodeView/VerifyEmailCodeView.vue'
  );

const ForgotPasswordView = () =>
  import(
    /* webpackChunkName: "authentication" */ '@/views/ForgotPasswordView/ForgotPasswordView.vue'
  );

const StatsView = () =>
  import(/* webpackChunkName: "stats" */ '@/views/StatsView/StatsView.vue');

const PastStatsView = () =>
  import(
    /* webpackChunkName: "stats" */ '@/views/PastStatsView/PastStatsView.vue'
  );

const ChallengesView = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/views/ChallengesView/ChallengesView.vue'
  );

const OpenChallengesView = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/views/OpenChallengesView/OpenChallengesView.vue'
  );

const PastChallengesView = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/views/PastChallengesView/PastChallengesView.vue'
  );

const ChallengeDetailsView = () =>
  import(
    /* webpackChunkName: "challenges" */ '@/views/ChallengeDetailsView/ChallengeDetailsView.vue'
  );

const StoreView = () =>
  import(/* webpackChunkName: "store" */ '@/views/StoreView/StoreView.vue');

const StoreItemAllView = () =>
  import(
    /* webpackChunkName: "store" */ '@/views/StoreItemAllView/StoreItemAllView.vue'
  );

const PurchasesView = () =>
  import(
    /* webpackChunkName: "store" */ '@/views/PurchasesView/PurchasesView.vue'
  );

const StorePrizesView = () =>
  import(
    /* webpackChunkName: "store" */ '@/views/StorePrizesView/StorePrizesView.vue'
  );

const CollectableCategoriesView = () =>
  import(
    /* webpackChunkName: "store" */ '@/views/CollectableCategoriesView/CollectableCategoriesView.vue'
  );

const LeaderboardView = () =>
  import(
    /* webpackChunkName: "leaderboard" */ '@/views/LeaderboardView/LeaderboardView.vue'
  );

const LeaderboardPodiumView = () =>
  import(
    /* webpackChunkName: "leaderboard" */ '@/views/LeaderboardPodiumView/LeaderboardPodiumView.vue'
  );

const ProfileView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/ProfileView/ProfileView.vue'
  );

const MoreView = () =>
  import(/* webpackChunkName: "more" */ '@/views/MoreView/MoreView.vue');

const AccountSettingsView = () =>
  import(
    /* webpackChunkName: "more" */ '@/views/AccountSettingsView/AccountSettingsView.vue'
  );

const DeleteAccountView = () =>
  import(
    /* webpackChunkName: "deleteAccount" */ '@/views/DeleteAccountView/DeleteAccountView.vue'
  );

const FarewellView = () =>
  import(
    /* webpackChunkName: "deleteAccount" */ '@/views/FarewellView/FarewellView.vue'
  );

const TermsAndConditions = () =>
  import(
    /* webpackChunkName: "more" */ '@/views/MoreView/TermsAndConditions/TermsAndConditions.vue'
  );

const TermsAndConditionsView = () =>
  import(
    /* webpackChunkName: "more" */ '@/views/TermsAndConditionsView/TermsAndConditionsView.vue'
  );

const NoConnectionView = () =>
  import(
    /* webpackChunkName: "disconnected" */ '@/views/NoConnectionView/NoConnectionView.vue'
  );

const MaintenanceView = () =>
  import(
    /* webpackChunkName: "disconnected" */ '@/views/MaintenanceView/MaintenanceView.vue'
  );

const AchievementsView = () =>
  import(
    /* webpackChunkName: "achievements" */ '@/views/AchievementsView/AchievementsView.vue'
  );

const ProfileMetricActivityView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/ProfileMetricActivityView/ProfileMetricActivityView.vue'
  );

const ProfileQRCodeView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/ProfileQRCodeView/ProfileQRCodeView.vue'
  );

const ProfileKudosView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/ProfileKudosView/ProfileKudosView.vue'
  );

const ProfileCustomizationView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/ProfileCustomizationView/ProfileCustomizationView.vue'
  );

const AvatarProfileCustomizationView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/AvatarProfileCustomizationView/AvatarProfileCustomizationView.vue'
  );

const BackgroundProfileCustomizationView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/BackgroundProfileCustomizationView/BackgroundProfileCustomizationView.vue'
  );

const AcitivityView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/ActivityView/ActivityView.vue'
  );

const AssetProfileCustomizationView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/AssetProfileCustomizationView/AssetProfileCustomizationView.vue'
  );

const UsernameProfileCustomizationView = () =>
  import(
    /* webpackChunkName: "profile" */ '@/views/UsernameProfileCustomizationView/UsernameProfileCustomizationView.vue'
  );

const ReceivedKudosView = () =>
  import(
    /* webpackChunkName: "kudos" */ '@/views/ReceivedKudosView/ReceivedKudosView.vue'
  );

const ReceivedKudosByPeerView = () =>
  import(
    /* webpackChunkName: "kudos" */ '@/views/ReceivedKudosByPeerView/ReceivedKudosByPeerView.vue'
  );

const ReceivedKudosByKudoTypeView = () =>
  import(
    /* webpackChunkName: "kudos" */ '@/views/ReceivedKudosByKudoTypeView/ReceivedKudosByKudoTypeView.vue'
  );

const SurveysView = () =>
  import(
    /* webpackChunkName: "surveys" */ '@/views/MoreView/SurveysView/SurveysView.vue'
  );

const SurveyView = () =>
  import(/* webpackChunkName: "survey" */ '@/views/SurveyView/SurveyView.vue');

const SearchUsersView = () =>
  import(
    /* webpackChunkName: "leaderboard" */ '@/views/SearchUsersView/SearchUsersView.vue'
  );

const IndexedUserList = () =>
  import(
    /* webpackChunkName: "leaderboard" */ '@/components/user/IndexedUserList/IndexedUserList.vue'
  );

const FirstTimeExperienceView = () =>
  import(
    /* webpackChunkName: "ftx" */ '@/views/FirstTimeExperienceView/FirstTimeExperienceView.vue'
  );

const QrCodeReaderView = () =>
  import(
    /* webpackChunkName: "qrCode" */ '@/views/QrCodeReaderView/QrCodeReaderView.vue'
  );

const IconsView = () =>
  import(/* webpackChunkName: "icons" */ '@/views/IconsView/IconsView.vue');

const NotificationCenterView = () =>
  import(
    /* webpackChunkName: "notifications" */ '@/views/NotificationCenterView/NotificationCenterView.vue'
  );

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: Routes.LOGIN,
    name: 'login',
    component: LoginView,
    meta: {
      layoutType: AppLayoutType.EMPTY,
    },
  },
  {
    path: '/login/forgot-password',
    name: 'forgot-password-login',
    component: ForgotPasswordView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
      layoutType: AppLayoutType.EMPTY,
    },
  },
  {
    path: '/login/change-password',
    name: 'change-password-login',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
      layoutType: AppLayoutType.EMPTY,
    },
    component: ChangePasswordView,
  },
  {
    path: '/activate-biometric',
    name: 'activate-biometric',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
      layoutType: AppLayoutType.EMPTY,
      headerType: AppHeaderType.THIN,
    },
    component: ActivateBiometricView,
  },
  {
    path: '/',
    name: 'main',
    redirect: Routes.CHALLENGES,
  },
  {
    path: Routes.CHALLENGES,
    component: ChallengesView,
    redirect: Routes.CHALLENGES_OPEN,
    name: 'challenges',
    meta: {
      layoutType: AppLayoutType.PADDINGLESS,
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: Routes.CHALLENGES_OPEN,
        name: 'open-challenges',
        component: OpenChallengesView,
        meta: {
          tabs: [
            { path: Routes.CHALLENGES_OPEN, name: 'open-challenges' },
            { path: Routes.CHALLENGES_PAST, name: 'past-challenges' },
          ],
        },
      },
      {
        path: Routes.CHALLENGES_PAST,
        name: 'past-challenges',
        component: PastChallengesView,
        meta: {
          tabs: [
            { path: Routes.CHALLENGES_OPEN, name: 'open-challenges' },
            { path: Routes.CHALLENGES_PAST, name: 'past-challenges' },
          ],
        },
      },
    ],
  },
  {
    path: `${Routes.CHALLENGE_DETAILS}/:reference`,
    name: 'challenge-details',
    component: ChallengeDetailsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.EMPTY,
    },
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: LeaderboardView,
    redirect: '/leaderboard/podium',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    children: [
      {
        path: 'podium',
        name: 'leaderboard',
        component: LeaderboardPodiumView,
      },
      {
        path: 'search',
        name: 'users-search',
        redirect: '/leaderboard/search/users',
        component: SearchUsersView,
        meta: {
          headerType: AppHeaderType.THIN,
          tabs: [
            {
              name: 'alphabetically-indexed-users-search',
              path: '/leaderboard/search/users',
            },
            {
              name: 'teams-indexed-users-search',
              path: '/leaderboard/search/teams',
            },
          ],
        },
        children: [
          {
            path: 'users',
            name: 'alphabetically-indexed-users-search',
            component: IndexedUserList,
            meta: {
              isQueryByTeamName: false,
            },
          },
          {
            path: 'teams',
            name: 'teams-indexed-users-search',
            component: IndexedUserList,
            meta: {
              isQueryByTeamName: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
    component: ProfileView,
    redirect: '/profile/purchases',
    children: [
      {
        path: 'activity',
        name: 'profile-activity',
        component: AcitivityView,
      },
      {
        path: 'purchases',
        name: 'profile-purchases',
        component: PurchasesView,
      },
      {
        path: 'kudos',
        name: 'profile-kudos',
        component: ProfileKudosView,
      },
    ],
  },
  {
    path: '/profile/activity',
    name: 'profile-metric-activity',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.EMPTY,
    },
    component: ProfileMetricActivityView,
  },

  {
    path: Routes.PROFILE_CUSTOMIZATION,
    name: 'profile-customization',
    redirect: Routes.AVATAR_PROFILE_CUSTOMIZATION,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.EMPTY,
    },
    component: ProfileCustomizationView,
    children: [
      {
        path: Routes.AVATAR_PROFILE_CUSTOMIZATION,
        name: 'avatar-profile-customization',
        component: AvatarProfileCustomizationView,
      },
      {
        path: Routes.BACKGROUND_PROFILE_CUSTOMIZATION,
        name: 'background-profile-customization',
        component: BackgroundProfileCustomizationView,
      },
      {
        path: `${Routes.ASSET_PROFILE_CUSTOMIZATION}/:reference`,
        name: 'asset-profile-customization',
        component: AssetProfileCustomizationView,
      },
      {
        path: Routes.USERNAME_PROFILE_CUSTOMIZATION,
        name: 'username-profile-customization',
        component: UsernameProfileCustomizationView,
      },
    ],
  },
  {
    path: Routes.MORE,
    name: 'more',
    component: MoreView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.FOOTERLESS,
      headerType: AppHeaderType.THIN,
    },
  },
  {
    path: Routes.NOTIFICATION_CENTER,
    name: 'notification-center',
    component: NotificationCenterView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.FOOTERLESS,
      headerType: AppHeaderType.THIN,
    },
  },
  {
    path: Routes.ACCOUNT_SETTINGS,
    name: 'accountSettings',
    component: AccountSettingsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.FOOTERLESS,
      headerType: AppHeaderType.THIN,
    },
  },
  {
    path: '/change-password',
    name: 'changePassword',
    redirect: '/change-password/verify',
    children: [
      {
        path: 'verify',
        name: 'ChangeFlowVerifyPassword',
        component: VerifyPasswordView,
        meta: {
          authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
          layoutType: AppLayoutType.FOOTERLESS,
          headerType: AppHeaderType.THIN,
        },
      },
      {
        path: 'change',
        name: 'changeFlowChangePassword',
        component: ChangePasswordView,
        meta: {
          authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
          layoutType: AppLayoutType.FOOTERLESS,
          headerType: AppHeaderType.THIN,
        },
      },
    ],
  },

  {
    path: '/forgot-password',
    name: 'forgotPassword',
    redirect: '/forgot-password/verify',
    children: [
      {
        path: 'verify',
        name: 'ForgotFlowForgotPassword',
        component: VerifyEmailCodeView,
        meta: {
          authenticationRequirement:
            RouteAuthenticationRequirement.AUTHENTICATED,
          layoutType: AppLayoutType.FOOTERLESS,
          headerType: AppHeaderType.THIN,
        },
      },
      {
        path: 'change',
        name: 'ForgotFlowChangePassword',
        component: ChangePasswordView,
        meta: {
          authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
          layoutType: AppLayoutType.FOOTERLESS,
          headerType: AppHeaderType.THIN,
        },
      },
    ],
  },

  {
    path: Routes.DELETE_ACCOUNT,
    name: 'deleteAccount',
    component: DeleteAccountView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.FOOTERLESS,
      headerType: AppHeaderType.THIN,
    },
  },

  {
    path: Routes.FAREWELL,
    name: 'farewell',
    component: FarewellView,
    meta: {
      layoutType: AppLayoutType.EMPTY,
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
    },
  },

  {
    path: Routes.ACHIEVEMENTS,
    name: 'achievements',
    component: AchievementsView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.FOOTERLESS,
      headerType: AppHeaderType.PROFILE,
    },
  },

  {
    path: '/stats',
    component: StatsView,
    name: 'stats',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.FOOTERLESS,
      headerType: AppHeaderType.THIN,
    },
    children: [
      {
        path: '/stats/history',
        name: 'stats-history',
        component: PastStatsView,
      },
    ],
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditions,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.FOOTERLESS,
      headerType: AppHeaderType.THIN,
    },
  },
  {
    path: Routes.SURVEYS,
    name: 'surveys',
    component: SurveysView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.FOOTERLESS,
      headerType: AppHeaderType.THIN,
    },
  },
  {
    path: Routes.STORE,
    name: 'store',
    redirect: '/store/prize',
    component: StoreView,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      tabs: [
        {
          path: '/store/prize',
          name: 'prize',
        },
        {
          path: '/store/avatar',
          name: 'avatar',
        },
        {
          path: '/store/background',
          name: 'background',
        },
      ] as AppTab[],
    },
    children: [
      {
        path: 'prize',
        name: 'prize',
        component: StorePrizesView,
      },
      {
        path: 'avatar',
        name: 'avatar',
        component: CollectableCategoriesView,
        meta: {
          collectableTypeName: CollectableTypeName.AVATAR,
        },
      },

      {
        path: 'avatar/:id',
        name: 'avatarCategory',
        component: StoreItemAllView,
        meta: {
          collectableTypeName: CollectableTypeName.AVATAR,
        },
      },
      {
        path: 'background',
        name: 'background',
        component: CollectableCategoriesView,
        meta: {
          collectableTypeName: CollectableTypeName.BACKGROUND,
        },
      },
      {
        path: 'background/:id',
        name: 'backgroundCategory',
        component: StoreItemAllView,
        meta: {
          collectableTypeName: CollectableTypeName.BACKGROUND,
        },
      },
    ],
  },
  {
    path: '/surveys',
    name: 'survey',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.EMPTY,
    },
    component: SurveyView,
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
      layoutType: AppLayoutType.EMPTY,
    },
    component: TermsAndConditionsView,
  },
  {
    path: Routes.KUDOS,
    name: 'received-kudos',
    component: ReceivedKudosView,
    redirect: `${Routes.KUDOS}/peers`,
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
      layoutType: AppLayoutType.PADDINGLESS,
      headerType: AppHeaderType.PROFILE,
    },
    children: [
      {
        path: 'peers',
        name: 'received-kudos-by-peer',
        component: ReceivedKudosByPeerView,
      },
      {
        path: 'kudo-types',
        name: 'received-kudos-by-kudo-type',
        component: ReceivedKudosByKudoTypeView,
      },
    ],
  },
  {
    path: '/502',
    name: 'no-connection',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
      layoutType: AppLayoutType.EMPTY,
    },
    component: NoConnectionView,
  },
  {
    path: '/503',
    name: 'maintenance',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
      layoutType: AppLayoutType.EMPTY,
    },
    component: MaintenanceView,
  },
  {
    path: '/ftx',
    name: 'ftx',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
      layoutType: AppLayoutType.EMPTY,
    },
    component: FirstTimeExperienceView,
  },
  {
    path: '/icons',
    name: 'icons',
    component: IconsView,
    beforeEnter: (_to, _from, next) => {
      if (process.env.NODE_ENV !== 'development') {
        next({ path: '/' });
      }
      next();
    },
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: '/qr-code',
    name: 'qr-code',
    meta: {
      authenticationRequirement: RouteAuthenticationRequirement.UNIVERSAL,
      layoutType: AppLayoutType.FOOTERLESS,
      headerType: AppHeaderType.THIN,
      // tabs: [
      //   { name: 'qr-code-scan', path: '/qr-code/scan' },
      //   { name: 'qr-code-my-code', path: '/qr-code/myCode' },
      // ] as AppTab[],
    },
    children: [
      {
        path: 'scan',
        name: 'qr-code-scan',
        component: QrCodeReaderView,
      },
      {
        path: 'myCode',
        name: 'qr-code-my-code',
        component: ProfileQRCodeView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { isAvailableBiometric, toggleBiometric, verifyBiometricIsAvailable } =
    useBiometric();

  const tokenStore = useTokenStore();
  const profileStore = useProfileStore();
  const translationsStore = useTranslationsStore();
  const surveysStore = useSurveysStore();
  const kudosStore = useKudosStore();
  const notificationCenterStore = useNotificationCenterStore();

  await verifyBiometricIsAvailable();

  const { isNativePlatform } = useNativePlatform();

  const routeRequiresAuthentication = to.matched.some(
    (route) =>
      route.meta.authenticationRequirement ===
      RouteAuthenticationRequirement.AUTHENTICATED,
  );

  const routeHasUniversalAccess =
    to.meta.authenticationRequirement ===
    RouteAuthenticationRequirement.UNIVERSAL;

  let isAuthenticated = !isEmpty(tokenStore.token);

  const profileIsNotLoaded = profileStore.userProfile === undefined;

  if (isAuthenticated && profileIsNotLoaded) {
    try {
      if (isNativePlatform && toggleBiometric.value === 'enabled') {
        tokenStore.setToken('', '', '');
        resetFeatures();
        localStorage.clear();
      } else {
        await loadFeatures(featureTogglesService.getFeatureToggles);

        const promises: Promise<void>[] = [
          profileStore.loadProfile(),
          profileStore.loadProfileSite(),
          profileStore.loadDetailedProfile(),
        ];

        if (isFeatureEnabled(Feature.OPERATOR_ACHIEVEMENTS)) {
          promises.push(profileStore.countLogin());
        }

        if (process.env.NODE_ENV !== 'development') {
          promises.push(translationsStore.loadTranslations());
        }

        if (
          isFeatureEnabled(Feature.SURVEY_CUSTOM_QUESTIONS) ||
          isFeatureEnabled(Feature.SURVEY_DEFAULT_QUESTIONS)
        ) {
          promises.push(surveysStore.loadSurvey());
        }

        if (isFeatureEnabled(Feature.KUDOS)) {
          promises.push(
            kudosStore.getReceivedKudosRecordByType(undefined, true),
          );
        }

        if (isFeatureEnabled(Feature.NOTIFICATION_CENTER)) {
          await notificationCenterStore.loadNotificationCenter();
        }

        await Promise.all(promises);
      }
    } catch (e) {
      profileStore.userProfile = {} as UserProfile;
      next('/503');

      return;
    }

    isAuthenticated = !isEmpty(tokenStore.token);
  }

  if (profileStore.userProfile !== undefined) {
    i18n.global.locale.value = profileStore.userProfile.userConfig
      ? profileStore.userProfile?.userConfig.userLanguage
      : Locale.US;
  }

  if (
    routeRequiresAuthentication &&
    isAuthenticated &&
    profileStore.userProfile !== undefined
  ) {
    i18n.global.locale.value =
      profileStore.userProfile.userConfig.userLanguage || Locale.US;

    const areTermsAndConditionsAccepted =
      profileStore.userProfile.user.termsConditions;

    const shouldChangePassword = profileStore.userProfile.user.changePassword;

    const isRedirectedToTermsAndConditions = to.name === 'terms-conditions';

    const shouldSeeFTX =
      isFeatureEnabled(Feature.FTX) &&
      profileStore.userProfile.userConfig.showFtx;

    if (!areTermsAndConditionsAccepted && !isRedirectedToTermsAndConditions) {
      next('/terms-conditions');

      return;
    }
    if (
      isNativePlatform &&
      !toggleBiometric.value &&
      from.fullPath === '/login'
    ) {
      if (isAvailableBiometric.value) {
        next('/activate-biometric');
        return;
      }
    }

    if (shouldChangePassword) {
      if (!profileStore.userProfile.user.email) {
        await profileStore.updateDeviceToken('');
        tokenStore.setToken('', '', '');

        next('/login');

        return;
      }

      next('/login/change-password');

      return;
    }

    if (shouldSeeFTX) {
      next('/ftx');

      return;
    }
  }

  if (
    routeRequiresAuthentication &&
    !isAuthenticated &&
    !routeHasUniversalAccess
  ) {
    next('/login');

    return;
  }

  if (
    !routeRequiresAuthentication &&
    !routeHasUniversalAccess &&
    isAuthenticated
  ) {
    next('/');

    return;
  }

  next();
});

export default router;
