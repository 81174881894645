import { PostHogInterface } from '@vaibe-github-enterprise/kd-kse-fe-commons';

export interface PostHogOperator extends PostHogInterface {
  orgId: string;
  b2cId: string;
  deviceModel: string;
  deviceName: string;
  devicePlatformVersion: string;
}
export interface EventsTracking {
  modeChallenge?: string;
  typeChallenge?: string;
  initialTimeEvent?: string;
  endTimeEvent?: string;
  durationInSeconds?: number;
  motiveFailed?: string;
  motiveWarning?: string;
  identifier?: string;
  ftxPage?: string;
  itemReference?: string;
  topicKey?: string;
  redirect?: string;
  itemType?: string;
  b2cId?: string;
  route?: string;
}

export enum PostHogEvents {
  NOTIFICATION_OPENED = 'notifications_opened',
  REQUESTING_PROFILE = 'requesting_profile',
  ERROR_REQUESTING_PROFILE = 'error_requesting_profile',
  QRCODE_INIT = 'read_qrcode_init',
  QRCODE_SUCCESS = 'read_qrcod_success',
  QRCODE_ERROR = 'read_qrcode_error',
  FTX_DISMISS_MODAL_OPERATOR = 'ftx_dismiss_modal_operator',
  FTX_DISMISS_JOYRIDE_OPERATOR = 'ftx_dismiss_joyride_operator',
  FTX_DISMISS_INITIAL_OPERATOR = 'ftx_dismiss_initial_operator',
  FTX_COMPLETE_JOYRIDE_OPERATOR = 'ftx_complete_joyride_operator',
  FTX_REVISIT_TOUR_OPERATOR = 'ftx_revisit_tour_operator',
  COMPLETE_CUSTOM_AVATAR = 'complete_custom_avatar',
  CANCEL_CUSTOM_AVATAR = 'cancel_custom_avatar',
  STORE_PURCHASE = 'store_purchase',
  PULL_TO_REFRESH = 'pull_to_refresh',
  PULL_TO_REFRESH_ERROR = 'refresh_error',
  ERROR_STORE_PURCHASE = 'error_store_purchase',
  ERROR_LOAD_STORE = 'error_load_store',
  ERROR_LOGIN_OPERATOR = 'error_login_operator',
  BIOMETRICS_ENABLED = 'biometrics_enabled',
  BIOMETRICS_DISABLED = 'biometrics_disabled',
  DELETE_ACCOUNT = 'delete_account',
  CHANGE_PASSWORD = 'change_password',
  FORGOT_PASSWORD = 'forgot_password',
  MARK_ALL_AS_READ = 'mark_all_as_read',
}
